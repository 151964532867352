import revive_payload_client_vbUjpl9UnI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.1_eslint-ts-patch@8.56.0-0_rollup@2.79.1_terser@5.27.0_typescript@5.3.3_vite@5.1.0_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_HKsRMPmiuO from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.1_eslint-ts-patch@8.56.0-0_rollup@2.79.1_terser@5.27.0_typescript@5.3.3_vite@5.1.0_vue-tsc@1.8.27/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ljQ6YRyFlG from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.1_eslint-ts-patch@8.56.0-0_rollup@2.79.1_terser@5.27.0_typescript@5.3.3_vite@5.1.0_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_WCLIu8ha2C from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.1_eslint-ts-patch@8.56.0-0_rollup@2.79.1_terser@5.27.0_typescript@5.3.3_vite@5.1.0_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_yvJ2YCzyXH from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.1_eslint-ts-patch@8.56.0-0_rollup@2.79.1_terser@5.27.0_typescript@5.3.3_vite@5.1.0_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_amkTdqyQaX from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.1_eslint-ts-patch@8.56.0-0_rollup@2.79.1_terser@5.27.0_typescript@5.3.3_vite@5.1.0_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_MzCDxu9LMj from "/opt/buildhome/repo/.nuxt/unocss.mjs";
import chunk_reload_client_65aKMtxZUk from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.10.1_eslint-ts-patch@8.56.0-0_rollup@2.79.1_terser@5.27.0_typescript@5.3.3_vite@5.1.0_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import vue_loading_overlay_JK73elCvRP from "/opt/buildhome/repo/plugins/vue-loading-overlay.ts";
import vue3_toastify_OGYNDsiW9E from "/opt/buildhome/repo/plugins/vue3-toastify.ts";
export default [
  revive_payload_client_vbUjpl9UnI,
  unhead_HKsRMPmiuO,
  router_ljQ6YRyFlG,
  payload_client_WCLIu8ha2C,
  check_outdated_build_client_yvJ2YCzyXH,
  components_plugin_KR1HBZs4kY,
  prefetch_client_amkTdqyQaX,
  unocss_MzCDxu9LMj,
  chunk_reload_client_65aKMtxZUk,
  vue_loading_overlay_JK73elCvRP,
  vue3_toastify_OGYNDsiW9E
]