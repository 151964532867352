<template>
    <div>
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
    </div>
</template>

<script setup lang="ts">
useHead({
    titleTemplate: (titleChunk) => {
        return titleChunk ? `Hot Dog Yoga - ${titleChunk}` : "Hot Dog Yoga";
    },
});
</script>
