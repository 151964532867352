import { default as completexrUlYltlUGMeta } from "/opt/buildhome/repo/pages/complete.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
export default [
  {
    name: completexrUlYltlUGMeta?.name ?? "complete",
    path: completexrUlYltlUGMeta?.path ?? "/complete",
    meta: completexrUlYltlUGMeta || {},
    alias: completexrUlYltlUGMeta?.alias || [],
    redirect: completexrUlYltlUGMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/complete.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  }
]